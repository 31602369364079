"use strict";
var $ = window.jQuery = window.$ = require("./../../bower_components/jquery/dist/jquery.js");

require("./../../bower_components/prism/prism.js");

var initCustomWidgets = function () {
	require('./modules/feedback')();
	require('./modules/mobileDetect')();
	require('./modules/faq')();
	require('./modules/header')();
};

$(initCustomWidgets);
