module.exports = function() {

	$('.header').each(function() {
		var $container = $(this);
		var $toggle = $container.find('.header__toggle');
		var $close = $container.find('.header__close');

		$toggle.on('click', function() {
			$container.addClass('header_open');
		});

		$close.on('click', function() {
			$container.removeClass('header_open');
		});

		$(document).on('click', function(event) {
			if(!$(event.target).parents('.header').length && $container.hasClass('header_open')) {
				$container.removeClass('header_open');
			}
		});
	});

};