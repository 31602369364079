module.exports = function() {

	$('.faq').each(function() {
		var $container = $(this);
		var $items = $container.find('.faq__item');

		$items.each(function() {
			var $item = $(this);
			var $toggle = $item.find('.faq__header');
			var $body = $item.find('.faq__body');

			$toggle.on('click', function() {
				var bodyHeight;

				if ($item.hasClass('faq__item_active')) {
					bodyHeight = $body.outerHeight();
					$body.css('height', bodyHeight);

					setTimeout(function() {
						$body.css('height', 0);
					}, 0);

					setTimeout(function() {
						$item.removeClass('faq__item_visible');
						$item.removeClass('faq__item_active');
						$body.css('height', '');
					}, 200);
				} else {
					$item.addClass('faq__item_active');
					bodyHeight = $body.outerHeight();
					$body.css('height', 0);

					setTimeout(function() {
						$item.addClass('faq__item_visible');
						$body.css('height', bodyHeight);
					}, 0);

					setTimeout(function() {
						$body.css('height', '');
					}, 200);
				}
			});
		});
	});

};